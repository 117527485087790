<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto mt-5 mb-10" elevation="1">
          <v-card-title class="pb-5">
            <BaseScreenHeader
              title="Fitness Tools"
              width="90%"
              screenInfo="TLS-001"
              :showBack="false"
            />
          </v-card-title>
          <v-card-subtitle :class="textSizeXSmall">
            Our fitness tools help you get all the necessary instruments and
            information for building the best workout that suits you, as well as
            understanding your progress.
          </v-card-subtitle>
          <v-row dense>
            <v-col cols="12" md="6" class="pa-4">
              <v-card
                min-height="260"
                title="Calculators"
                hover
                @click="launch('/calculators/all')"
              >
                <v-divider class="accent lighten-3" />
                <v-sheet
                  color="lightHeader"
                  class="silver--text text--darken-3"
                >
                  <v-card-title :class="textSize">
                    Calculators <v-spacer />
                    <v-icon
                      :large="!isPhone"
                      title="Calculators"
                      tile
                      color="teal"
                    >
                      mdi-checkbox-blank
                    </v-icon>
                  </v-card-title>
                </v-sheet>
                <v-card-subtitle :class="textSizeXSmall + ' py-5'">
                  A collection of fitness calculators such as, 1RM (One
                  Repetition Maximum) Calculator , Body Fat Percentage (BFP)
                  Calculator, BMI Calculator, Reverse 1RM calculator, etc.
                </v-card-subtitle>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    title="Use calculators"
                    fab
                    outlined
                    medium
                    color="accent"
                  >
                    <v-icon x-large class="accent--text"
                      >mdi-chevron-double-right</v-icon
                    >
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="pa-4">
              <v-card
                min-height="260"
                title="Rep Scheme Builder"
                hover
                @click="launch('/repschemes')"
              >
                <v-divider class="accent lighten-3" />
                <v-sheet
                  color="lightHeader"
                  class="silver--text text--darken-3"
                >
                  <v-card-title :class="textSize">
                    Rep Scheme Builder <v-spacer />
                    <v-icon
                      :large="!isPhone"
                      title="Rep Scheme Builder"
                      tile
                      color="accent"
                    >
                      mdi-checkbox-blank
                    </v-icon>
                  </v-card-title>
                </v-sheet>
                <v-card-subtitle :class="textSizeXSmall + ' py-5'">
                  Tool for viewing, understanding and building new rep schemes
                  (repetition schemes)
                </v-card-subtitle>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    title="Rep Scheme Builder"
                    fab
                    outlined
                    medium
                    color="accent"
                  >
                    <v-icon x-large class="accent--text">
                      mdi-chevron-double-right
                    </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import util from '@/mixins/util.js'
export default {
  mixins: [util],
  name: 'Tools',
  data: () => ({}),
  beforeMount() {},
  methods: {
    launch(url) {
      this.$router.push({
        path: url
      })
    }
  }
}
</script>
