<template>
  <v-container>
    <BaseScreenHeader
      title="Rep Schemes"
      width="90%"
      screenInfo="TLS-003"
      :showBack="false"
      :refresh="true"
      @refresh="refresh"
    />
    <v-card-actions class="pb-0 px-0">
      <v-spacer />
      <BaseActionButton
        text
        label=""
        title="Add New Rep Scheme"
        icon="mdi-plus-circle-outline"
        class="charcoal paper--text mr-n5"
        @clickedThis="$router.push('/repschemes/new')"
      />
    </v-card-actions>
    <v-sheet class="charcoal">
      <v-card-text class="px-1 pt-">
        <BaseSearch
          dark
          :items="loadedList"
          :label="loadedList.length + ' Repschemes'"
          @search_results="setResults"
          @reset="resetSearch"
          what="display_name"
          :searchIn="['display_name', 'type', 'utility']"
        />
      </v-card-text>
      <BaseLoading :loaded="loaded" label="Loading Rep Schemes" />
      <v-card
        v-for="(item, index) in repschemes"
        :key="index"
        elevation="1"
        class="mt-2  mx-1 transparent paper--text"
        :style="charcoalTile"
        @click="viewDetail(item)"
      >
        <v-card-actions :class="textSize + ' pb-0 paper--text'">
          {{ item.name }}
        </v-card-actions>
        <v-card-actions class="pa-0 ml-2">
          <span :class="textSizeXSmall + ' progressActive--text'">
            {{ item.utility }}, {{ item.type }}
          </span>
        </v-card-actions>
        <v-card-text :class="textSizeSmall + ' py-2 pl-2'">
          <span :class="textSizeLarge + ' progressActive--text'">
            {{ item.sets }}
          </span>
          <span class="progressActive--text ml-n2 caption">sets</span>
          <span :class="textSize + ' silver--text ml-2'">
            {{ item.scheme }}
          </span>
        </v-card-text>
        <v-card-text class="silver--text py-0 px-2" :class="textSizeXSmall">
          <span v-html="item.description"></span>
        </v-card-text>
        <v-card-actions class="pa-0" v-if="canEdit(item)">
          <v-spacer />
          <v-btn class="paper--text" title="Edit Rep Scheme" text>
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-sheet>
  </v-container>
</template>
<script>
import { appConfig } from '@/store/helpers.js'
import axios from 'axios'
import util from '@/mixins/util.js'
import debounce from 'lodash/debounce'

export default {
  mixins: [util],
  name: 'Tools',
  data: () => ({
    itemsPerPageArray: [20, 50, 100],
    itemsPerPage: 20,
    page: 1,
    filter: 'all',
    repschemes: [],
    loadedList: [],
    loaded: false,
    switchNum: 0,
    search: '',
    debouncedScroll: null,
    debounce: null,
    pagesize: 20,
    currentPage: 0,
    totalCount: 100, //fetch from API
    bottom: false,
    refreshing: false
  }),
  beforeMount() {
    this.loadPage()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        this.currentPage++
        this.loadPage()
      }
    }
  },
  computed: {
    itemsCount() {
      return this.repschemes.length
    },
    numberOfPages() {
      return Math.ceil(this.repschemes.length / this.itemsPerPage)
    },
    ...appConfig
  },
  methods: {
    refresh() {
      this.refreshing = true
      this.currentPage = 0
      this.loadPage()
    },
    resetSearch() {
      this.repschemes = this.loadedList
    },
    setResults(value) {
      this.repschemes = value
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    viewDetail(value) {
      this.$router.push('/repschemes/' + value.id)
    },
    loadPage() {
      {
        this.loaded = false
        return axios
          .get(
            this.baseURL +
              '/tools/repschemes/scroll/' +
              this.currentPage +
              '/' +
              this.pagesize,
            {}
          )
          .then(response => {
            if (response.status == 200) {
              if (this.refreshing) {
                this.repschemes = response.data.data
                this.loadedList = this.repschemes.slice(0)
              } else {
                this.repschemes = this.repschemes.concat(response.data.data)
              }

              this.loadedList = this.repschemes.slice(0)
              this.refreshing = false
              this.loaded = true
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>

}
