<template>
  <v-container>
    <BaseScreenHeader
      title="Repscheme Details"
      width="90%"
      screenInfo="TLS-004"
    />

    <v-card left elevation="0" class="transparent pa-0 mt-4">
      <v-card-actions class="pa-0 mb-2">
        <BaseActionButton
          v-if="item.userid == userid || xvy"
          medium
          label="DELETE"
          plain
          icon=""
          color="orange"
          class=""
          @clickedThis="showDeleteDialog = true"
        />
        <v-spacer />
        <BaseActionButton color="paper" plain @clickedThis="save" />
      </v-card-actions>
      <v-sheet rounded class="transparent charcoalTile mx-0 pa-2">
        <v-card-actions :class="textSize + ' paper--text pb-0 px-2'">
          {{ item.name }}
        </v-card-actions>
        <v-card-actions :class="textSize + ' py-0 pr-0'">
          <v-switch
            dark
            color="progressActive"
            class="mr-2 mt-n1"
            light
            title="Metric?"
            v-model="metric"
          >
            <template v-slot:label>
              <span class="silver--text">Metric?</span>
            </template>
          </v-switch>
          <v-spacer />
          <v-switch
            dark
            v-if="canEdit(item)"
            color="progressActive"
            class="mr-2 mt-n1"
            light
            title="Edit?"
            v-model="edit"
          >
            <template v-slot:label>
              <span class="silver--text">Edit?</span>
            </template>
          </v-switch>
        </v-card-actions>

        <template v-if="!edit">
          <v-card-actions
            class="success--text pt-2 pb-0 mb-2"
            :class="textSizeSmall"
          >
            <v-spacer />
            {{ item.type }}, {{ item.utility }}
          </v-card-actions>
          <v-card-actions class="pa-0 paper--text" :class="textSize">
            <v-icon color="success" class="mt-n2" x-large>
              {{ iconNumber(item.sets - 1) }}
            </v-icon>
            <span :class="textSizeSmall + ' silver--text'">sets </span>
            <v-spacer />
            <span class="ml-2">{{ item.scheme }}</span>
            <span :class="textSizeSmall + ' silver--text ml-1'">reps</span>
          </v-card-actions>
          <template v-if="item.description != '' && item.description != null">
            <v-card-title :class="textSize + ' paper--text py-0 px-2'">
              Description
            </v-card-title>
            <v-card-text class="silver--text pt-0 px-2" :class="textSizeSmall">
              <span v-html="item.description" />
            </v-card-text>
          </template>
        </template>
        <v-card-text v-else class="pa-0">
          <v-row dense>
            <v-col cols="12" class="display-1">
              {{ item.repscheme }}
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dark
                filled
                color="success"
                v-model="item.name"
                ref="name"
                :error-messages="nameErrors"
                :counter="50"
                label="Name"
                prepend-inner-icon="mdi-billboard"
                @input="$v.item.name.$touch()"
                @blur="$v.item.name.$touch()"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                dark
                filled
                color="success"
                ref="utility"
                v-model="item.utility"
                :items="utilityTypes"
                label="Utility"
                chips
                deletable-chips
                hint="What is the utility? Strength, Hypertrophy?"
                persistent-hint
                item-text="text"
                item-value="id"
                prepend-inner-icon="mdi-chevron-right-box"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                dark
                filled
                color="success"
                ref="type"
                v-model="item.type"
                :items="repschemeTypes"
                label="Type"
                chips
                deletable-chips
                hint="What is the Type? Pyramid, Wave, etc.?"
                persistent-hint
                item-text="text"
                item-value="id"
                prepend-inner-icon="mdi-sort"
              />
            </v-col>
            <v-col cols="12" md="12" class="mt-4">
              <tiptap-vuetify
                v-model="item.description"
                :extensions="extensions"
                :error-messages="descriptionErrors"
                placeholder="Enter a meaningful description"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-sheet>
      <template>
        <v-card-title :class="textSize + ' paper--text pb-0 mt-5 px-2'">
          Sets Information
        </v-card-title>
        <v-card
          rounded
          flat
          class="transparent charcoalTile  mb-2 mx-1"
          v-for="set in sets"
          :key="set.value"
          @click="showRow(set)"
        >
          <v-card-actions class="py-0 ">
            <v-divider color="progressActive" />
            <span class="mx-2">
              <v-icon large color="progressActive" v-if="set.setnum <= 10">
                {{ iconNumber(set.setnum - 1) }}
              </v-icon>
              <span v-else :class="textSize + ' progressActive--text'">
                {{ set.setnum }}
              </span>
            </span>
            <v-divider color="progressActive" />
          </v-card-actions>
          <v-card-text class="px-2 py-0 silver--text">
            Reps: {{ set.reps }}
            <v-spacer />
            Rest Time: {{ set.rest }} seconds
            <v-spacer />
            Load: {{ set.load }}% of 1RM
            <v-spacer />
            Increment: {{ set.load_increment }} {{ unit(metric) }}
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-spacer />
            <BaseActionButton
              v-if="canEdit(item)"
              icon="mdi-pencil"
              color="silver"
              plain
              label="Edit"
            />
          </v-card-actions>
        </v-card>
      </template>
    </v-card>
    <v-dialog
      v-model="showDeleteDialog"
      :fullscreen="isPhone"
      persistent
      :width="dialogWidth"
      overlay-opacity="0.97"
      overlay-color="charcoal"
      @keydown.esc="cancelDelete"
      v-touch:swipe.left="cancelDelete"
    >
      <v-card
        flat
        class="d-flex flex-column charcoal noscroll"
        tile
        v-touch:swipe.left="cancelDelete"
      >
        <BaseCloseDialogHeader
          @cancel="showDeleteDialog = false"
          heading="Delete Repscheme?"
        />
        <v-card-text class="px-0">
          <v-card-subtitle class="title paper--text">
            Repscheme
            <div class="body-1 progressActive--text">{{ item.name }}</div>
          </v-card-subtitle>
          <v-card-subtitle class="silver--text body-1 pt-5">
            Review the information above. Click the
            <span class="progressActive--text">Delete</span> button to remove
            this repscheme.
          </v-card-subtitle>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="px-0">
          <v-spacer />
          <BaseActionButton
            label="Delete"
            class="charcoal paper--text"
            icon="mdi-trash-can-outline"
            @clickedThis="deleteScheme"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showEditSetDialog"
      :fullscreen="isPhone"
      :width="dialogWidth"
      persistent
      overlay-opacity="0.97"
      overlay-color="charcoal"
      @keydown.esc="cancelEdit"
    >
      <v-card
        class="charcoal d-flex flex-column noscroll-x"
        tile
        flat
        v-touch:swipe.left="cancelEdit"
      >
        <BaseCloseDialogHeader
          @cancel="cancelEdit"
          :heading="'Edit Set # ' + selectedForEdit.setnum"
        />

        <v-card-subtitle :class="textSizeXSmall + ' silver--text py-4 px-2'">
          Edit the set information below then click "Done". You need to
          <span class="progressActive--text">save the repscheme </span>for the
          changes to take effect.
        </v-card-subtitle>
        <v-divider />
        <v-card-text class="px-2 py-0 mt-4">
          <v-row>
            <v-col cols="6">
              <v-text-field
                dark
                filled
                class="enlarged-input-small"
                v-model="selectedForEdit.reps"
                counter="2"
                ref="reps"
                min="1"
                max="99"
                label="Reps"
                type="number"
                prepend-inner-icon="mdi-dots-horizontal-circle"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                dark
                filled
                class="enlarged-input-small"
                min="1"
                max="600"
                counter="3"
                v-model="selectedForEdit.rest"
                ref="rest"
                label="Rest"
                type="number"
                prepend-inner-icon="mdi-clock"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                dark
                filled
                class="enlarged-input-small"
                v-model="selectedForEdit.load"
                counter="3"
                min="1"
                max="100"
                label="Load (%1RM)"
                type="number"
                prepend-inner-icon="mdi-label-percent"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                dark
                filled
                class="enlarged-input-small"
                v-model="selectedForEdit.load_increment"
                counter="3"
                min="1"
                max="200"
                label="Load Increment"
                type="number"
                prepend-inner-icon="mdi-weight"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="px-0">
          <v-spacer />
          <BaseActionButton
            icon="mdi-checkbox-outline"
            color="paper"
            plain
            label="Done"
            @clickedThis="done"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { appConfig } from '@/store/helpers.js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import utilityTypes from '@/json/utilitytypes.json'
import repschemeTypes from '@/json/repschemetypes.json'
import util from '@/mixins/util.js'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  HorizontalRule
} from 'tiptap-vuetify'

export default {
  components: { TiptapVuetify },
  mixins: [util, validationMixin],
  name: 'Tools',
  data: () => ({
    metric: false,
    edit: false,
    utilityTypes: utilityTypes,
    repschemeTypes: repschemeTypes,
    filter: 'all',
    item: [],
    sets: [],
    selectedForEdit: {},
    loaded: false,
    showEditSetDialog: false,
    showDeleteDialog: false,
    switchNum: 0,
    search: '',
    headers: [
      {
        text: 'Rep Schemes',
        sortable: false,
        value: 'name',
        class: 'accent--text display-1'
      }
    ],
    extensions: [
      Paragraph,
      Bold,
      Italic,
      [
        Heading,
        {
          options: {
            levels: [5, 6]
          }
        }
      ],
      HorizontalRule,
      ListItem,
      BulletList,
      OrderedList,
      HardBreak
    ]
  }),
  validations: {
    item: {
      name: {
        required
      },
      description: {
        required
      }
    }
  },
  beforeMount() {
    this.load()
  },
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.item.name.$dirty) return errors
      !this.$v.item.name.required && errors.push('Exercise name is required.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.item.description.$dirty) return errors
      !this.$v.item.description.required &&
        errors.push('Description is required.')
      return errors
    },
    ...appConfig
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  watch: {
    showDeleteDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    showEditSetDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    }
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        if (this.showDeleteDialog) this.cancelDelete()
        else if (this.showEditSetDialog) this.cancelEdit()
      }
    },
    confirmDelete() {
      this.showDeleteDialog = true
    },
    cancelDelete() {
      this.showDeleteDialog = false
    },
    cancelEdit() {
      this.showEditSetDialog = false
    },
    done() {
      this.calculateRepScheme()
      this.switchNum++
      this.showEditSetDialog = false
    },
    calculateRepScheme() {
      let rs = ''
      if (this.isEmpty(this.item.sets)) {
        this.item.repscheme = rs
        return
      }

      this.sets.forEach(set => {
        rs = rs.concat(set.reps.toString() + '-')
      })
      this.item.repscheme = rs.slice(0, -1)
    },
    showRow(set) {
      this.showEditSetDialog = true
      this.selectedForEdit = set
    },
    loadValue(set) {
      if (set.load_type == '1RM') return set.load + '%'
      else return set.load_impperial + 'lbs / ' + set.load_metric + ' Kg'
    },
    save() {
      return axios
        .put(this.baseURL + '/tools/repschemes', {
          item: this.item,
          sets: this.sets
        })
        .then(response => {
          if (response.status == 200) {
            this.$router.push('/repschemes')
            this.$store.dispatch(
              'notification/addNotification',
              'Rep scheme was succesfully edited.',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteScheme() {
      return axios
        .delete(this.baseURL + '/tools/repschemes/' + this.item.id, {})
        .then(response => {
          if (response.status == 200) {
            this.$router.push('/repschemes')
            this.$store.dispatch(
              'notification/addNotification',
              'Rep scheme was succesfully deleted.',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    load() {
      {
        return axios
          .get(this.baseURL + '/tools/repschemes/load/' + this.id, {})
          .then(response => {
            if (response.status == 200) {
              this.item = response.data.data
              if (this.item.details != null) {
                this.sets = JSON.parse(this.item.details)
              }
              this.loaded = true
              this.switchnum++
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
