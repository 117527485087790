<template>
  <v-container>
    <BaseScreenHeader
      title="Fitness Clocks"
      screenInfo="CLK-001"
      :showBack="true"
    />
    <v-sheet color="transparent" class="my-5">
      <v-tabs
        v-model="tab"
        grow
        dark
        icons-and-text
        show-arrows
        background-color="charcoal "
        @change="resetTabs"
        slider-size="2"
      >
        <v-tabs-slider color="progressActive" />
        <v-tab
          dark
          :style="charcoalTile"
          href="#hiit"
          class="charcoal silverLink rounded-t-lg"
          active-class="progressActive charcoal--text"
        >
          Tabata / HIIT
          <v-icon>mdi-run-fast</v-icon>
        </v-tab>
        <v-tab
          :style="charcoalTile"
          href="#wod"
          class="charcoal silverLink rounded-t-lg"
          active-class="progressActive charcoal--text"
        >
          <template v-if="!isPhone">Crosstraining or </template> WOD
          <v-icon>mdi-kettlebell</v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="tab"
        rounded
        class="mt-2 rounded charcoal"
        :style="charcoalTileMenu"
      >
        <v-tab-item key="hiit" value="hiit">
          <v-card-text v-if="tab == 'hiit'">
            <v-row dense>
              <v-col>
                <v-card-title class="paper--text pb-0 pt-1">
                  <v-divider class="charcoal lighten-2 mr-2" />
                  <span v-if="!isPhone">TABATA / HIIT</span>
                  <span class="caption silver--text">
                    (High Intensity Interval Training)
                  </span>
                  <v-divider class="charcoal lighten-2 ml-2" />
                </v-card-title>
                <v-card-actions class="py-0">
                  <v-switch
                    dark
                    color="success"
                    v-model="tabata"
                    label="Tabata?"
                  />
                  <v-spacer />
                  <v-switch
                    dark
                    color="success"
                    v-model="byTime"
                    label="By Time"
                  />
                </v-card-actions>
                <v-row dense>
                  <v-col cols="6" md="3">
                    <v-text-field
                      dark
                      :disabled="tabata"
                      class="enlarged-input-small"
                      color="success"
                      v-model="workout.active_time"
                      ref="active"
                      :error-messages="activeErrors"
                      :counter="5"
                      label="Active (seconds)"
                      prepend-inner-icon="mdi-clock-in"
                      @input="activeTimeChanged"
                      @focus="$event.target.select()"
                    />
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-text-field
                      dark
                      :disabled="tabata"
                      class="enlarged-input-small"
                      color="success"
                      v-model="workout.rest_time_set"
                      ref="rest"
                      :error-messages="restErrors"
                      :counter="5"
                      label="Rest (seconds)"
                      prepend-inner-icon="mdi-clock-out"
                      @input="restTimeChanged"
                      @focus="$event.target.select()"
                    />
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-text-field
                      dark
                      class="enlarged-input-small"
                      color="success"
                      :disabled="!byTime"
                      v-model="workout.total_time"
                      ref="total"
                      :error-messages="totalErrors"
                      :counter="5"
                      label="Total (minutes)"
                      prepend-inner-icon="mdi-clock-digital"
                      @input="totalTimeChanged"
                      @focus="$event.target.select()"
                    />
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-text-field
                      dark
                      class="enlarged-input-small"
                      color="success"
                      :disabled="byTime"
                      v-model="workout.roundsHiit"
                      ref="rounds"
                      :counter="2"
                      :error-messages="hiitRoundsErrors"
                      label="Rounds"
                      type="number"
                      prepend-inner-icon="mdi-circle-double"
                      @input="roundsHiitChanged"
                      @focus="$event.target.select()"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>
        <v-tab-item key="wod" value="wod">
          <v-card-text v-if="tab == 'wod'">
            <v-row dense>
              <v-col>
                <v-card-title class="paper--text pb- pt-1">
                  <v-divider class="charcoal lighten-2 mr-2" />
                  <span v-if="!isPhone">Crosstraining or WOD</span>
                  <span class="caption silver--text">
                    (Crossfit Style Clocks)
                  </span>
                  <v-divider class="charcoal lighten-2 ml-2" />
                </v-card-title>
                <v-card-actions class="px-0 pt-0 pb-5">
                  <v-spacer />
                  <BaseActionButton
                    label="EMOM"
                    :large="!isPhone"
                    title="Every Minute on The Minute"
                    icon="mdi-clock-in"
                    :color="xmode=='emom'?'progressActive':'paper'"
                    class="mr-2 charcoalTileMenu rounded-t-lg paper--text"
                    text
                    @clickedThis="setEmom"
                  />
                  <v-spacer />
                  <BaseActionButton
                    label="4 Time"
                    :large="!isPhone"
                    title="For Time"
                    icon="mdi-clock-check-outline"
                    text
                    :color="xmode=='time'?'progressActive':'paper'"
                    class="mr-2 charcoalTileMenu rounded-t-lg paper--text"
                    @clickedThis="setForTime"
                  />
                  <v-spacer />
                  <BaseActionButton
                    label="AMRAP"
                    :large="!isPhone"
                    title="As Many Reps As Possible"
                    icon="mdi-update"
                    text
                    :color="xmode=='amrap'?'progressActive':'paper'"
                    class="charcoalTileMenu rounded-t-lg paper--text"
                    @clickedThis="setAmrap"
                  />
                  <v-spacer />
                </v-card-actions>
                <v-card-text class="text-center py-0">
                  <v-divider class="my-1 silver lighten-3" />
                  <v-row v-if="xmode == 'emom'" dense>
                    <v-col cols="6" md="3">
                      <v-text-field
                        dark
                        class="enlarged-input-small"
                        color="success"
                        v-model="workout.active.minutes"
                        ref="activeMinutes"
                        :error-messages="activeErrorsMinutes"
                        :counter="5"
                        label="Active (minutes)"
                        prepend-inner-icon="mdi-clock-in"
                        @input="activeMinutesChanged"
                        @focus="$event.target.select()"
                      />
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field
                        dark
                        class="enlarged-input-small"
                        color="success"
                        v-model="workout.active.seconds"
                        ref="activeSeconds"
                        :error-messages="activeErrorsSeconds"
                        :counter="5"
                        label="(seconds)"
                        prepend-inner-icon="mdi-clock-in"
                        @input="activeSecondsChanged"
                        @focus="$event.target.select()"
                      />
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field
                        dark
                        class="enlarged-input-small"
                        color="success"
                        v-model="workout.rest_time_set"
                        ref="rest"
                        :error-messages="restErrors"
                        :counter="5"
                        label="Rest (seconds)"
                        prepend-inner-icon="mdi-clock-out"
                        @input="restTimeSetChanged"
                        @focus="$event.target.select()"
                      />
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field
                        dark
                        class="enlarged-input-small"
                        color="success"
                        v-model="workout.roundsWod"
                        ref="roundsWod"
                        :counter="2"
                        :error-messages="wodRoundsErrors"
                        label="Rounds"
                        type="number"
                        prepend-inner-icon="mdi-circle-double"
                        @input="roundsWodChanged"
                        @focus="$event.target.select()"
                      />
                    </v-col>
                  </v-row>
                  <v-row justify="center" v-if="xmode == 'time'">
                    <v-col cols="12" md="6">
                      <v-text-field
                        dark
                        class="enlarged-input-small"
                        color="success"
                        v-model="workout.active.minutes"
                        ref="activeMinutes"
                        :error-messages="activeErrorsMinutes"
                        :counter="2"
                        label="Total Maximum Active Time  (minutes)"
                        prepend-inner-icon="mdi-clock-in"
                        @input="totalActiveChanged"
                        @focus="$event.target.select()"
                      />
                    </v-col>
                  </v-row>
                  <v-row justify="center" v-if="xmode == 'amrap'">
                    <v-col cols="12" md="6">
                      <v-text-field
                        dark
                        class="enlarged-input-small"
                        color="success"
                        v-model="workout.total_time"
                        ref="activeMinutes"
                        :error-messages="activeErrorsMinutes"
                        :counter="2"
                        label="Total Maximum Active Time  (minutes)"
                        prepend-inner-icon="mdi-clock-in"
                        @input="totalActiveChanged"
                        @focus="$event.target.select()"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
    <v-spacer />
    <v-card-actions class="mt-5">
      <v-spacer />
      <v-btn
        dark
        :disabled="!ready"
        fab
        x-large
        @click="startWorkout"
        elevation="0"
        color="charcoal"
        :style="charcoalTileMenu"
      >
        <v-icon x-large class="paper--text" v-if="ready">
          mdi-play-box
        </v-icon>
        <v-icon x-large class="paper--text" v-else>
          mdi-checkbox-blank
        </v-icon>
      </v-btn>
      <v-spacer />
    </v-card-actions>

    <v-dialog
      :value="dialog"
      :z-index="zIndex"
      transition="dialog-bottom-transition"
      overlay-opacity="0.97"
      fullscreen
      persistent
      :key="switchNum"
    >
      <v-card elevation="2" tile class="charcoal noscroll d-flex flex-column">
        <BaseCloseDialogHeader heading="Workout Clock" @cancel="cancel" />
        <v-card-actions class="py-0 mt-n3">
          <v-img
            :src="imageLogo"
            contain
            title="Logo"
            class="mt-"
            :height="isPhone ? 40 : 60"
          />
        </v-card-actions>
        <v-sheet rounded class="pa-2  mt-n8 charcoal">
          <video-background
            class="mt-n5"
            :src="videoLoop"
            style="max-height: 100px; height: 50vh;opacity:0.25"
            overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)"
          >
          </video-background>
        </v-sheet>
        <template>
          <v-sheet class="charcoal silver--text mt-n10 pt-">
            <template v-if="!completed">
              <v-row justify="center" class="py-2">
                <BaseIconCounter
                  :rounds="workout.rounds"
                  :current="currentRound"
                  :dark="true"
                />
              </v-row>
              <v-card-actions v-if="!prestart" :class="textSizeLarge + ' pb-0'">
                <v-divider />
                <span
                  v-if="!(xmode == 'amrap' || xmode == 'time')"
                  class="mt-2 mx-2 success--text"
                >
                  ROUND: {{ currentRound }} of {{ workout.rounds }}
                </span>
                <span v-if="xmode == 'amrap'" class="mt-2">
                  AMRAP
                </span>
                <span v-if="xmode == 'time'" class="mt-2">
                  FOR TIME (RFT)
                </span>
                <v-divider />
              </v-card-actions>

              <v-card-actions :class="textSizeLarge + ' py-0'" v-if="prestart">
                <v-divider />
                <span class="success--text mt-2 mx-2">GET READY</span>
                <v-divider />
              </v-card-actions>
            </template>
            <template v-else>
              <v-card-actions :class="textSizeLarge + ' py-0'">
                <v-divider />
                <span class="mt-2 mx-2">FINISHED!!</span>
                <v-divider />
              </v-card-actions>
            </template>
            <template>
              <v-card-text
                v-if="xmode == 'amrap'"
                class="pa-1 mt-2 silver--text title text-uppercase text-center"
              >
                Total Time: {{ workout.total_time }} minutes
              </v-card-text>
              <v-card-text
                v-if="xmode == 'emom'"
                class="pa-1 mt-2 silver--text title text-uppercase text-center"
              >
                Total Time: {{ workout.total_time }} minutes
              </v-card-text>
              <v-card-text class="pa-0 success--text  text-center">
                <span :class="textSizeXSmall">{{ prettyNow() }}</span>
              </v-card-text>
              <template v-if="xmode != 'amrap'">
                <v-card-text
                  :class="
                    textSizeSmall +
                      ' paper--text text-uppercase text-center py-0'
                  "
                >
                  Elapsed
                </v-card-text>
                <v-card-text
                  class="silver--text display-1 text-uppercase text-center py-0"
                >
                  {{ elapsedMinutesStr }} : {{ elapsedSecondsStr }}
                </v-card-text>
              </template>
            </template>
            <v-card-actions class="py-0">
              <v-spacer />
              <template v-if="mode == 'active'">
                <span
                  :class="
                    isPhone ? 'title success--text' : 'display-3 success--text'
                  "
                >
                  WORK
                </span>
              </template>
              <template v-if="mode == 'rest' && !completed">
                <span
                  :class="
                    isPhone ? 'title paper--text' : 'display-3 paper--text'
                  "
                >
                  REST
                </span>
              </template>
              <template v-if="mode == ''">
                <span
                  :class="
                    isPhone ? 'title silver--text' : 'display-3 silver--text'
                  "
                >
                  READY
                </span>
              </template>
              <v-spacer />
            </v-card-actions>
          </v-sheet>
          <v-progress-linear
            color="progressActive"
            v-model="progress"
            height="3"
          >
          </v-progress-linear>
        </template>
        <v-sheet class="pt-5 charcoal" color="" width="100%">
          <v-card-actions v-if="!completed" class="py-0 mt-5">
            <v-spacer />
            <v-progress-circular
              v-if="prestart"
              :rotate="-90"
              :size="circleSize"
              :width="circleWidth"
              :value="100 - value * 20"
              color="paper"
            >
              <span :class="textSizeXLarge">
                {{ value > 0 ? value : 'GO' }}
              </span>
            </v-progress-circular>
            <v-progress-circular
              v-if="!prestart && xmode != 'amrap'"
              :rotate="-90"
              :size="circleSize"
              :width="circleWidth"
              :value="
                mode == 'active'
                  ? 100 - value * (100 / workout.active_time)
                  : 100 - value * (100 / workout.rest_time_set)
              "
              :color="getColor()"
            >
              <span :class="textSizeXLarge">
                {{ value }}
              </span>
            </v-progress-circular>
            <v-spacer />
          </v-card-actions>
          <v-card-actions :class="textSizeXLarge + ' pt-2'">
            <v-spacer />
            <span v-if="xmode != 'amrap'" :class="getColor() + '--text'">
              {{ valueMinutes }} : {{ valueSeconds }}
            </span>
            <span :class="getColor() + '--text'" v-else>
              {{ elapsedMinutesStr }} : {{ elapsedSecondsStr }}
            </span>
            <v-spacer />
          </v-card-actions>
        </v-sheet>
        <v-sheet class="charcoal" v-if="!loggedIn">
          <v-card-text>
            <span class="caption silver--text">
              <span
                class="paper--text mr-1"
                @click="$router.push('/login')"
                title="Login"
                style="cursor: pointer;"
              >
                Login
              </span>
              or
              <span
                class="paper--text ml-1 mr-1"
                @click="$router.push('/register')"
                title="Register"
                style="cursor: pointer;"
              >
                Register
              </span>
              to save your data and track your progress.
            </span>
          </v-card-text>
        </v-sheet>
        <v-spacer />
        <v-card-actions class="pa-0">
          <v-spacer />
          <BaseActionButton
            v-if="completed"
            :large="!isPhone"
            icon="mdi-checkbox-multiple-marked-outline"
            plain
            color="paper"
            label="Done"
            title="Done"
            @clickedThis="done"
          />
          <BaseActionButton
            v-if="!completed && (xmode == 'time' || xmode == 'amrap')"
            text
            label="Next Round"
            icon="mdi-checkbox-multiple-marked-outline"
            color="success"
            @clickedThis="nextRound"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { appConfig } from '@/store/helpers.js'
import { validationMixin } from 'vuelidate'
import { required, between } from 'vuelidate/lib/validators'
import util from '@/mixins/util.js'
import sounds from '@/json/sounds.json'
import moment from 'moment/src/moment'

export default {
  mixins: [util, validationMixin],
  data: () => ({
    sounds: sounds,
    xmode: 'emom',
    interval: {},
    workout: {},
    initial: {
      active_time: 0,
      active: { minutes: 1, seconds: 0, total: 0 },
      rest_time_set: 0,
      total_time: 0,
      rounds: 0,
      roundsHiit: 0,
      roundsWod: 0
    },
    mode: '',
    tab: '',
    byTime: true,
    tabata: false,
    completed: false,
    value: 5,
    valueMinutes: 0,
    valueSeconds: 0,
    elapsedMinutes: 0,
    elapsedSeconds: 0,
    elapsedMinutesStr: '00',
    elapsedSecondsStr: '00',
    countdown: 5,
    currentRound: 0,
    dialog: false,
    prestart: true,
    zIndex: 0,
    starttime: '',
    endtime: '',
    switchNum: 0,
    sound: false,
    audio: null,
    beep: null,
    go: null,
    work: null,
    rest: null,
    videoLoop: null,
    imageLogo: null
  }),

  beforeMount() {
    this.videoLoop = this.isPhone
      ? require(`@/assets/LoopSmall.mp4`)
      : require(`@/assets/Loop.mp4`)
    this.workout = { ...this.initial }
    this.go = new Audio(this.sounds['go'])
    this.audio = new Audio(this.sounds['5secs'])
    this.beep = new Audio(this.sounds['ready'])
    this.go = new Audio(this.sounds['go'])
    this.rest = new Audio(this.sounds['rest'])
    this.work = new Audio(this.sounds['work'])
    this.audio.preload = 'auto'
    this.beep.preload = 'auto'
    this.go.preload = 'auto'
    this.rest.preload = 'auto'
    this.work.preload = 'auto'

    this.imageLogo = require('@/assets/Logo100.png')
  },
  beforeDestroy() {
    clearInterval(this.interval)
    this.screenOff()
  },
  validations: {
    workout: {
      roundsHiit: {
        required,
        between: between(1, 99)
      },
      roundsWod: {
        required,
        between: between(1, 99)
      },
      active_time: {
        between: between(1, 600)
      },
      active_time_seconds: {
        between: between(0, 60)
      },
      active_time_minutes: {
        between: between(0, 99)
      },
      rest_time_set: {
        between: between(1, 600)
      },
      total_time: {
        between: between(0, 60)
      }
    }
  },
  watch: {
    dialog(value) {
      if (value) {
        this.screenOn()
      } else this.screenOff()
    },
    tabata(newValue) {
      if (newValue) {
        this.byTime = false
        this.workout.active_time = 20
        this.workout.rest_time_set = 10
        this.workout.roundsHiit = 8
      }
      this.calculateTimes()
    }
  },
  computed: {
    circleSize() {
      return this.isPhone ? 200 : 300
    },
    circleWidth() {
      return this.isPhone ? 5 : 10
    },
    ready() {
      let result = false

      if (this.tab == 'hiit') {
        result =
          this.workout.active_time > 0 &&
          this.workout.rest_time_set > 0 &&
          this.workout.roundsHiit > 0
      } else {
        if (this.xmode == 'emom') {
          result =
            this.workout.active.minutes > 0 &&
            this.workout.active.seconds >= 0 &&
            this.workout.rest_time_set >= 0 &&
            this.workout.roundsWod > 0
        }
        if (this.xmode == 'time') {
          result = this.workout.active.minutes > 0
        }
        if (this.xmode == 'amrap') {
          result = true
        }
      }
      return result
    },
    activeErrors() {
      const errors = []
      if (!this.$v.workout.active_time.$dirty) return errors
      !this.$v.workout.active_time.between &&
        errors.push(
          'The number of active seconds is invalid. Expected (1 to 600)'
        )
      return errors
    },
    activeErrorsMinutes() {
      const errors = []
      if (!this.$v.workout.active_time_minutes.$dirty) return errors
      !this.$v.workout.active_time_minutes.between &&
        errors.push(
          'The number of active minutes is invalid. Expected (1 to 99)'
        )
      return errors
    },
    activeErrorsSeconds() {
      const errors = []
      if (!this.$v.workout.active_time_seconds.$dirty) return errors
      !this.$v.workout.active_time_seconds.between &&
        errors.push(
          'The number of active seconds is invalid. Expected (1 to 600)'
        )
      return errors
    },
    restErrors() {
      const errors = []
      if (!this.$v.workout.rest_time_set.$dirty) return errors
      !this.$v.workout.rest_time_set.between &&
        errors.push(
          'The number of resting seconds between sets is invalid. Expected (1 to 600)'
        )
      return errors
    },
    totalErrors() {
      const errors = []
      if (!this.$v.workout.total_time.$dirty) return errors
      !this.$v.workout.total_time.between &&
        errors.push('The number of total minutes is invalid.')
      return errors
    },
    hiitRoundsErrors() {
      const errors = []
      if (!this.$v.workout.roundsHiit.$dirty) return errors
      !this.$v.workout.roundsHiit.required &&
        errors.push('The number of rounds  is required.')
      !this.$v.workout.roundsHiit.between &&
        errors.push('The number of rounds is invalid.')
      return errors
    },
    wodRoundsErrors() {
      const errors = []
      if (!this.$v.workout.roundsWod.$dirty) return errors
      !this.$v.workout.roundsWod.required &&
        errors.push('The number of rounds  is required.')
      !this.$v.workout.roundsWod.between &&
        errors.push('The number of rounds is invalid.')
      return errors
    },
    progress() {
      return Math.ceil((this.currentRound / this.workout.rounds) * 100)
    },
    ...appConfig
  },
  methods: {
    activeTimeChanged() {
      this.$v.workout.active_time.$touch()
      this.calculateTimes()
    },
    restTimeChanged() {
      this.$v.workout.rest_time_set.$touch()
      this.calculateTimes()
    },
    totalTimeChanged() {
      this.$v.workout.total_time.$touch()
      this.calculateTimes()
    },
    roundsHiitChanged() {
      this.$v.workout.roundsHiit.$touch()
      this.calculateTimes()
    },
    activeMinutesChanged() {
      this.$v.workout.active_time_minutes.$touch()
      this.calculateTimes()
    },
    activeSecondsChanged() {
      this.$v.workout.active_time_seconds.$touch()
      this.calculateTimes()
    },
    restTimeSetChanged() {
      this.$v.workout.rest_time_set.$touch()
      this.calculateTimes()
    },
    totalActiveChanged() {
      this.$v.workout.active_time_minutes.$touch()
      this.calculateTimes()
    },
    roundsWodChanged() {
      this.$v.workout.roundsWod.$touch()
      this.calculateTimes()
    },
    setEmom() {
      this.reset()
      this.xmode = 'emom'
    },
    setForTime() {
      this.reset()
      this.xmode = 'time'
    },
    setAmrap() {
      this.reset()
      this.xmode = 'amrap'
      this.calculateTimes()
    },
    nextRound() {
      this.currentRound++
      this.workout.rounds++
    },
    resetTabs() {
      this.reset()
    },
    calculateTimes() {
      if (this.xmode == 'time' || this.xmode == 'amrap') {
        this.workout.rounds = 1
        if (this.xmode == 'amrap') {
          this.workout.active.minutes = this.workout.total_time
        }
      } else {
        this.workout.rounds =
          this.tab == 'wod'
            ? Number.parseInt(this.workout.roundsWod)
            : Number.parseInt(this.workout.roundsHiit)
      }
      if (this.tab == 'wod') {
        this.workout.active.total =
          Number.parseInt(this.workout.active.minutes * 60) +
          Number.parseInt(this.workout.active.seconds)
        this.workout.active_time = this.workout.active.total
        if (this.xmode == 'time')
          this.workout.total_time = this.workout.active.minutes
        else if (this.xmode == 'amrap') {
          null
        } else
          this.workout.total_time = Math.ceil(
            ((this.workout.active.total +
              Number.parseInt(this.workout.rest_time_set)) *
              this.workout.rounds) /
              60
          )
      }

      let roundSeconds =
        Number.parseInt(this.workout.active_time) +
        Number.parseInt(this.workout.rest_time_set)
      let totalSeconds = 0
      if (this.tab == 'hiit') {
        if (this.byTime) {
          totalSeconds = Number.parseInt(this.workout.total_time * 60)
          this.workout.rounds = Number.parseInt(
            Math.ceil(totalSeconds / roundSeconds)
          )
        } else {
          totalSeconds = this.workout.roundsHiit * roundSeconds
          this.workout.total_time = Math.ceil(totalSeconds / 60)
          this.workout.rounds = Math.ceil(totalSeconds / roundSeconds)
        }
        this.workout.roundsHiit = this.workout.rounds
      }
    },
    reset() {
      this.screenOff()
      clearInterval(this.interval)
      this.tabata = false
      this.completed = false
      this.prestart = true
      this.currentRound = 0
      this.value = 0
      this.valueMinutes = 0
      this.valueSeconds = 0
      this.elapsedMinutes = 0
      this.elapsedSeconds = 0
      this.elapsedMinutesStr = '00'
      this.elapsedSecondsStr = '00'
      this.countdown = 5
      this.mode = ''
      this.xmode = 'emom'
      this.workout = { ...this.initial }
      this.workout.active.minutes = 0
      this.workout.active.seconds = 0
      this.workout.active.total = 0
      this.rounds = 0
      this.$v.$reset()
      this.switchNum++
    },
    done() {
      this.reset()
      this.dialog = false
    },
    cancel() {
      this.reset()
      this.dialog = false
    },
    getColor() {
      if (this.mode == 'active') return 'progressActive'
      else return 'paper'
    },
    playStart() {
      if (this.isNativeApp() && !this.isAndroid()) this.playStartNative()
      else this.go.play()
    },
    playActive() {
      if (this.isNativeApp() && !this.isAndroid()) this.playActiveNative()
      this.work.play()
    },
    playDing() {
      if (this.isNativeApp() && !this.isAndroid()) this.playDingNative()
      else this.beep.play()
    },
    playRest() {
      if (this.isNativeApp() && !this.isAndroid()) this.playRestNative()
      else this.rest.play()
    },
    playEnd() {
      if (this.isNativeApp() && !this.isAndroid()) this.playEndNative()
      else this.audio.play()
    },
    elapsed() {
      this.elapsedSeconds += 1
      if (this.elapsedSeconds == 60) {
        this.elapsedMinutes += 1
        this.elapsedSeconds = 0
      }

      this.elapsedSecondsStr = Number.parseInt(Math.floor(this.elapsedSeconds))
        .toString()
        .padStart(2, '0')

      this.elapsedMinutesStr = Number.parseInt(Math.floor(this.elapsedMinutes))
        .toString()
        .padStart(2, '0')
    },
    startWorkout() {
      if (!this.ready) return
      this.screenOn()
      this.dialog = true
      this.value = this.countdown
      this.starttime = this.now()

      if (this.prestart) {
        this.valueMinutes = this.valueMinutes.toString().padStart(2, '0')
        this.valueSeconds = this.valueSeconds.toString().padStart(2, '0')
        this.interval = setInterval(() => {
          if (this.value == 2) this.playStart()
          if (this.value == 0) {
            this.prestart = false
            clearInterval(this.interval)
            this.startActive()
            return true
          }
          this.value -= 1
          this.valueMinutes = Number.parseInt(Math.floor(this.value / 60))
            .toString()
            .padStart(2, '0')
          this.valueSeconds = Number.parseInt(Math.ceil(this.value % 60))
            .toString()
            .padStart(2, '0')
        }, 1000)
      }
    },
    startActive() {
      this.mode = 'active'
      let maxActive = Math.ceil(this.workout.active_time)
      let active = maxActive
      let startMoment = moment()

      if (this.tab == 'wod') active = Math.ceil(this.workout.active.total)
      this.value = active
      this.currentRound++
      this.playActive()
      this.interval = setInterval(() => {
        if (active == 0) {
          clearInterval(this.interval)
          this.startRest()
          return true
        } else {
          let passed = moment().diff(startMoment, 'seconds')
          active = maxActive - passed
          this.elapsed()
        }
        this.value = active

        this.valueMinutes = Number.parseInt(Math.floor(this.value / 60))
          .toString()
          .padStart(2, '0')
        this.valueSeconds = Number.parseInt(Math.ceil(this.value % 60))
          .toString()
          .padStart(2, '0')
      }, 1000)
    },
    startRest() {
      this.mode = 'rest'
      let rest = Math.ceil(this.workout.rest_time_set)
      this.value = rest
      this.playRest()
      if (this.mode == 'rest') {
        this.interval = setInterval(() => {
          if (rest == 1) {
            this.playDing()
          }
          if (rest == 0) {
            clearInterval(this.interval)
            if (this.currentRound < this.workout.rounds) {
              this.startActive()
            } else {
              this.completed = this.currentRound == this.workout.rounds
              if (this.completed) this.endtime = this.now()
              return true
            }
          } else {
            rest -= 1
            this.elapsed()
          }
          this.value = rest

          this.valueMinutes = Number.parseInt(Math.floor(this.value / 60))
            .toString()
            .padStart(2, '0')
          this.valueSeconds = Number.parseInt(Math.ceil(this.value % 60))
            .toString()
            .padStart(2, '0')
        }, 1000)
      }
    }
  }
}
</script>
