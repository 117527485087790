<template>
  <v-container>
    <BaseScreenHeader
      title="Settings"
      width="90%"
      screenInfo="STS-001"
      :showBack="true"
    />

    <v-card-subtitle :class="textSizeSmall + ' silver--text pt-0'">
      Quick acccess to common settings.
    </v-card-subtitle>
    <v-card-actions class="">
      <v-spacer />
      <BaseActionButton
        class="charcoal paper--text"
        icon="mdi-cached"
        label="Rebuild Cache"
        @clickedThis="showCacheDialog = true"
      />
    </v-card-actions>

    <BuildCache :show="showCacheDialog" @cancel="showCacheDialog = false" />
  </v-container>
</template>

<script>
import util from '@/mixins/util.js'
const BuildCache = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/BuildCache.vue')
export default {
  components: {
    BuildCache
  },
  mixins: [util],
  name: 'Settings',
  data: () => ({
    showCacheDialog: false
  }),
  beforeMount() {},
  methods: {}
}
</script>
