<template>
  <v-container>
    <BaseScreenHeader
      title="Manage News"
      screenInfo="ADM-002"
      :showBack="true"
    />

    <v-row v-if="loaded">
      <v-col v-for="item in news" :key="item.id" cols="12">
        <v-card
          rounded
          flat
          class="pa-2 mx-2 charcoal charcoalTile silver--text mb-2"
        >
          <v-card-text :class="textSizeSmall + ' paper--text px-0 pt-1 pb-0'">
            <span class="caption progressActive--text">
              {{ prettyDateTime(item.created) }}
            </span>
            <v-spacer />
            <v-switch
              color="progressActive"
              dark
              true-value="Yes"
              false-value="No"
              v-model="item.active"
              label="Active?"
            />
            <template v-if="item.edit == 'Yes'">
              <v-text-field
                dark
                label="Headline"
                ref="headline"
                filled
                color="progressActive"
                v-model="item.headline"
                :counter="50"
                prepend-icon="mdi-account-circle"
                @blur="item.headline = capitalize(item.headline)"
              />
              <tiptap-vuetify
                v-model="item.details"
                :extensions="extensions"
                placeholder="Enter news item details"
              />
              <v-text-field
                dark
                label="Display Order"
                ref="oderby"
                filled
                color="progressActive"
                v-model="item.orderby"
                :counter="2"
                prepend-icon="mdi-format-list-numbered"
                @blur="item.headline = capitalize(item.headline)"
              />
            </template>
            <template v-else>
              <v-card-text :class="textSizeXSmall + ' pa-0'">
                {{ item.headline }}
                <v-spacer />
                <span class="silver--text mt-2" v-html="item.details"> </span>
              </v-card-text>
            </template>
          </v-card-text>
          <v-card-actions class="px-0 ml-n2">
            <v-spacer />
            <v-btn
              v-if="item.edit == 'No'"
              color="silver"
              plain
              @click="edit(item)"
            >
              <v-icon color="silver">
                mdi-pencil
              </v-icon>
              Edit
            </v-btn>
            <template v-else>
              <v-btn color="silver" plain @click="item.edit = 'No'">
                <v-icon color="silver">
                  mdi-close
                </v-icon>
                Cancel Edit
              </v-btn>
              <v-btn color="silver" plain @click="save(item)">
                <v-icon color="silver">
                  mdi-content-save
                </v-icon>
                Save
              </v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  HardBreak,
  HorizontalRule
} from 'tiptap-vuetify'
export default {
  name: 'AdminNews',
  components: { TiptapVuetify },
  mixins: [util],
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      loaded: false,
      showAddDialog: false,
      added: '',
      news: [],
      extensions: [
        Paragraph,
        Bold,
        Italic,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3, 4, 5, 6]
            }
          }
        ],
        HorizontalRule,
        Link,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak
      ]
    }
  },
  beforeMount() {
    this.loadNews()
    this.alphabet = this.getAlphabet
  },
  computed: {
    ...appConfig
  },
  methods: {
    edit(item) {
      item.edit = 'Yes'
    },
    save(item) {
      return axios
        .put(this.baseURL + '/feed/news', {
          news: item
        })
        .then(response => {
          if (response.status == 200) {
            item.edit = 'No'
            this.loadNews()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    tagItem(value) {
      this.showTagDialog = true
      this.selectedForEdit = value
    },
    loadNews() {
      return axios.get(this.baseURL + '/feed/news/all', {}).then(response => {
        if (response.status == 200) {
          response.data.data.forEach(item => {
            item.edit = 'No'
          })
          this.news = response.data.data

          this.loaded = true
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    }
  }
}
</script>
