<template>
  <v-container>
    <v-card class="charcoal " height="80vh" d-flex flex-column flat>
      <BaseScreenHeader
        title="Wellness "
        screenInfo="WEL-001"
        :showBack="true"
      />
      <v-sheet class="charcoal pt-2">
        <v-tabs
          active-class="charcoal--text"
          class="paper--text"
          v-model="tab"
          center-active
          centered
          grow
          dark
          icons-and-text
          show-arrows
          background-color="transparent"
          v-touch:swipe.left="tabLeft"
          v-touch:swipe.right="tabRight"
        >
          <v-tabs-slider color="nicegreen" />
          <v-tab href="#Sauna" class="silver--text">
            Sauna
            <v-icon>mdi-fire</v-icon>
          </v-tab>
          <v-tab href="#Cold Therapy" class="silver--text">
            Cold Therapy
            <v-icon>mdi-snowflake</v-icon>
          </v-tab>
          <v-tab href="#Meditation" class="silver--text">
            Meditation
            <v-icon>mdi-meditation</v-icon>
          </v-tab>
        </v-tabs>
      </v-sheet>
      <v-sheet rounded class="transparent charcoalTileMenu pa-2">
        <v-tabs-items v-model="tab" class=" transparent pt-5 mt-0">
          <v-tab-item key="Sauna" value="Sauna">
            <v-row class="mb-2  px-2" justify="center">
              <v-col cols="12" md="4">
                <v-select
                  height="60"
                  solo
                  dark
                  v-model="selectedType"
                  :items="sauna"
                  label="Select Sauna Type"
                  item-text="text"
                  item-value="id"
                  return-object
                  prepend-inner-icon="mdi-fire"
                  color="nicegreen"
                  item-color="black"
                  background-color="charcoal lighten-2"
                  :menu-props="{
                    closeOnClick: true,
                    maxHeight: 310,
                    transition: 'fab-transition'
                  }"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item key="Cold Therapy" value="Cold Therapy">
            <v-row class="mb-2 px-2" justify="center">
              <v-col cols="12" md="4">
                <v-select
                  height="60"
                  solo
                  dark
                  v-model="selectedType"
                  :items="cold"
                  label="Select Cold Therapy Type"
                  item-text="text"
                  item-value="id"
                  return-object
                  prepend-inner-icon="mdi-snowflake"
                  color="nicegreen"
                  item-color="black"
                  background-color="charcoal lighten-2"
                  :menu-props="{
                    closeOnClick: true,
                    maxHeight: 310,
                    transition: 'fab-transition'
                  }"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item key="Meditation" value="Meditation">
            <v-row class="mb-2 px-2" justify="center">
              <v-col cols="12" md="4">
                <v-select
                  height="60"
                  solo
                  dark
                  v-model="selectedType"
                  :items="meditation"
                  label="Select Meditation Type"
                  item-text="text"
                  item-value="id"
                  return-object
                  prepend-inner-icon="mdi-meditation"
                  color="nicegreen"
                  item-color="black"
                  background-color="charcoal lighten-2"
                  :menu-props="{
                    closeOnClick: true,
                    maxHeight: 310,
                    transition: 'fab-transition'
                  }"
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
        <v-card-text class=" text-center pb-1">
          <v-row>
            <v-col cols="12">
              <BaseActionButton
                label="Timer"
                :large="!isPhone"
                title="Timer"
                :color="xmode == 'time' ? 'progressActive' : 'silver'"
                icon="mdi-clock-check-outline"
                text
                class="mr-2"
                @clickedThis="clickTimer"
              />
              <BaseActionButton
                label="Stopwatch"
                :large="!isPhone"
                title="Stopwatch"
                icon="mdi-timer"
                text
                :color="xmode != 'time' ? 'progressActive' : 'silver'"
                @clickedThis="clickStopwatch"
              />
            </v-col>
          </v-row>
          <v-card-actions class="py-0 ">
            <v-divider />
            <v-switch
              dark
              v-if="xmode == 'time'"
              label="Already Done?"
              color="accent"
              v-model="alreadyDone"
            />
            <v-divider />
          </v-card-actions>
          <v-row dense justify="center"  v-if="xmode != 'stopwatch'">
            <v-col cols="12">
              <span class="silver--text">Duration (minutes)</span>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field
                height="70"
                solo
                dark
                clearable
                color="nicegreen"
                background-color="charcoal lighten-2"
                v-if="xmode == 'time'"
                class="enlarged-input pb-0 px-4"
                v-model="workout.active.minutes"
                ref="activeMinutes"
                :error-messages="activeErrorsMinutes"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                label="Duration (minutes)"
                prepend-inner-icon="mdi-clock-in"
                @focus="$event.target.select()"
                @input="minutesChanged"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-spacer />
        <v-card-actions :class="xmode == 'time' ? 'pb-4 ' : 'py-4 '">
          <v-spacer />
          <v-btn
            dark
            fab
            height="100"
            width="100"
            :disabled="!ready"
            elevation="2"
            title="Start Timer"
            @click="startActivity"
            class="paper charcoal--text"
            v-if="!alreadyDone"
          >
            <v-icon size="60">
              {{ ready ? 'mdi-play-box' : 'mdi-checkbox-blank' }}
            </v-icon>
          </v-btn>
          <v-btn
            dark
            v-if="xmode == 'time' && alreadyDone"
            fab
            height="100"
            width="100"
            :disabled="!ready"
            title="Save"
            elevation="2"
            @click="record"
            class="paper charcoal--text pa-2"
          >
            <v-icon class="pa-2" size="60">
              mdi-content-save-all
            </v-icon>
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-sheet>
    </v-card>
    <v-dialog
      :value="dialog"
      :z-index="zIndex"
      transition="dialog-bottom-transition"
      fullscreen
      persistent
      :key="switchNum"
    >
      <v-card
        tile
        class="charcoal  py-2 d-flex flex-column noscroll"
        height="90vh"
      >
        <BaseCloseDialogHeader heading="Wellness Tracking" @cancel="cancel" />
        <v-card-actions class="py-0 mb-n4">
          <v-img
            class="mt-3"
            :src="imageLogo"
            contain
            title="Logo"
            :height="isPhone ? 40 : 60"
          />
        </v-card-actions>
        <v-card-text class="pa-0">
          <v-sheet rounded class="pa-2 pt-0 mt-n10 charcoal">
            <video-background
              :src="videoLoop"
              style="max-height: 80px; height: 50vh;opacity:0.35"
              overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)"
            >
            </video-background>
          </v-sheet>
          <v-card-text>
            <template>
              <v-sheet class="transparent silver--text text--lighten-2">
                <v-card-actions class="pb-0">
                  <v-spacer />
                  <span :class="textSizeLarge + ' paper--text text-uppercase'">
                    {{ tab }}
                  </span>
                  <v-spacer />
                </v-card-actions>
                <v-card-actions class="pt-0">
                  <v-spacer />
                  <span :class="textSize + ' paper--text'">
                    {{ selectedType.text }}
                  </span>
                  <v-spacer />
                </v-card-actions>
                <template v-if="!completed">
                  <v-row
                    justify="center"
                    :class="textSizeSmall + ' mt-2 pb-2 progressActive--text'"
                  >
                    <span>
                      {{ xmode == 'stopwatch' ? 'STOPWATCH' : 'TIMER' }}
                    </span>
                  </v-row>
                </template>
                <template v-else>
                  <v-row
                    justify="center"
                    :class="isPhone ? 'display-1' : 'display-3'"
                  >
                    <span class="mt-2">FINISHED!!</span>
                  </v-row>
                </template>
                <template>
                  <v-card-text class="pa-1 silver--text text-center">
                    <span :class="textSizeXSmall">{{ prettyNow() }}</span>
                    <v-spacer />
                    <span :class="textSizeXSmall + ' progressActive--text'"
                      >Finishing @ {{ endTime }}</span
                    >
                  </v-card-text>
                  <v-card-text
                    v-if="xmode != 'stopwatch'"
                    class="silver--text display-1 text-uppercase text-center py-0"
                  >
                    <h5 class="mb-0 silver--text">Elapsed</h5>
                    {{ elapsedMinutesStr }} : {{ elapsedSecondsStr }}
                  </v-card-text>
                </template>
              </v-sheet>
            </template>
            <v-card-actions v-if="!completed" class="pt-5">
              <v-spacer />
              <v-progress-circular
                :rotate="-90"
                :size="circleSize"
                :width="circleWidth"
                :value="
                  xmode == 'stopwatch'
                    ? 100
                    : 100 - value * (100 / workout.active_time)
                "
                :color="xmode == 'stopwatch' ? 'progressActive' : color"
                :class="textSizeXLarge"
              >
                <span v-if="xmode != 'stopwatch'" :class="color + '--text'">
                  {{ valueMinutes.toString().padStart(2, '0') }} :
                  {{ valueSeconds.toString().padStart(2, '0') }}
                </span>
                <span v-else :class="color + '--text'">
                  {{ elapsedMinutesStr }} : {{ elapsedSecondsStr }}
                </span>
              </v-progress-circular>
              <v-spacer />
            </v-card-actions>
          </v-card-text>
        </v-card-text>
        <v-spacer />
        <v-card-actions class=" px-1">
          <BaseActionButton
            :large="!isPhone"
            icon="mdi-close"
            plain
            color="silver"
            label="Cancel"
            title="Cancel"
            class="px-0 mx-0"
            @clickedThis="cancel"
          />
          <v-spacer />
          <BaseActionButton
            :large="!isPhone"
            :class="
              value == 0 ? 'charcoal nicegreen--text' : 'charcoal paper--text'
            "
            icon="mdi-checkbox-multiple-marked-outline"
            text
            :color="value == 0 ? 'nicegreen' : 'paper'"
            label="Done"
            title="Done"
            @clickedThis="done"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import { validationMixin } from 'vuelidate'
import { between } from 'vuelidate/lib/validators'
import util from '@/mixins/util.js'
import sounds from '@/json/sounds.json'
import wellness from '@/json/wellness.json'
import moment from 'moment/src/moment'

export default {
  mixins: [util, validationMixin],
  data: () => ({
    sounds: sounds,
    xmode: 'time',
    interval: {},
    workout: {},
    wellness: wellness,
    sauna: [],
    cold: [],
    meditation: [],
    selectedType: {},
    initial: {
      active_time: 15 * 60,
      active: { minutes: 15, seconds: 0, total: 0 }
    },
    tab: '',
    color: 'progressActive',
    byTime: true,
    completed: false,
    alreadyDone: false,
    value: 5,
    valueMinutes: 0,
    valueSeconds: 0,
    elapsedMinutes: 0,
    elapsedSeconds: 0,
    elapsedMinutesStr: '00',
    elapsedSecondsStr: '00',
    countdown: 5,
    dialog: false,
    zIndex: 10,
    startTime: '',
    endTime: '',
    switchNum: 0,
    sound: false,
    tic: 1000,
    timeout: 2000,
    audio: null,
    beep: null,
    silence: null,
    tabs: [
      { index: '0', name: 'Sauna' },
      { index: '1', name: 'Cold Therapy' },
      { index: '2', name: 'Meditation' }
    ],
    videoLoop: null,
    imageLogo: null
  }),

  beforeMount() {
    this.videoLoop = this.isPhone
      ? require(`@/assets/LoopSmall.mp4`)
      : require(`@/assets/Loop.mp4`)
    this.workout = { ...this.initial }
    this.audio = new Audio(this.sounds['5secs'])
    this.beep = new Audio(this.sounds['ready'])
    this.silence = new Audio(this.sounds['silence'])
    this.audio.preload = 'auto'
    this.beep.preload = 'auto'
    this.silence.preload = 'auto'
    this.imageLogo = require('@/assets/Logo100.png')
    this.setDropDowns()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  validations: {
    workout: {
      active_time: {
        between: between(1, 600)
      },
      active_time_minutes: {
        between: between(0, 99)
      }
    }
  },
  watch: {
    dialog(value) {
      if (value) {
        this.screenOn()
      } else this.screenOff()
    }
  },
  computed: {
    circleSize() {
      return this.isPhone ? 220 : 350
    },
    duration() {
      if (this.xmode == 'time' && this.alreadyDone)
        return parseFloat(this.workout.active.minutes)
      else return parseFloat(this.twoDigits(this.elapsedSeconds / 60))
    },
    circleWidth() {
      return Math.floor(this.circleSize / 25)
    },
    ready() {
      let result = false
      if (this.xmode == 'time') {
        result = this.workout.active.minutes >= 1
      }
      if (this.xmode == 'stopwatch') {
        result = true
      }
      return result && !this.isEmpty(this.selectedType)
    },
    activeErrorsMinutes() {
      const errors = []
      if (!this.$v.workout.active_time_minutes.$dirty) return errors
      !this.$v.workout.active_time_minutes.between &&
        errors.push(
          'The number of active minutes is invalid. Expected (1 to 99)'
        )
      return errors
    },
    ...appConfig
  },
  methods: {
    tabLeft() {
      let currentTab = this.tabs.find(el => el.name == this.tab)
      let currentIndex = parseInt(currentTab.index)
      if (currentIndex < this.tabs.length - 1) {
        let nextTab = this.tabs.find(el => el.index == currentIndex + 1)
        this.tab = nextTab.name
      }
    },
    tabRight() {
      let currentTab = this.tabs.find(el => el.name == this.tab)
      let currentIndex = parseInt(currentTab.index)
      if (currentIndex > 0) {
        let nextTab = this.tabs.find(el => el.index == currentIndex - 1)
        this.tab = nextTab.name
      }
    },
    minutesChanged() {
      this.$v.workout.active_time_minutes.$touch()
      this.calculateTimes()
    },
    clickTimer() {
      this.reset()
      this.xmode = 'time'
      this.calculateTimes()
    },
    clickStopwatch() {
      this.reset()
      this.xmode = 'stopwatch'
      this.calculateTimes()
    },
    record() {
      this.elapsedMinutes = this.workout.active.minutes
      this.elapsedSeconds = 0
      this.startTime = this.nowOffset(this.elapsedMinutes)
      this.done()
    },
    done() {
      this.saveActivity()
      this.reset()
      this.dialog = false
    },
    cancel() {
      this.reset()
      this.dialog = false
    },
    resetTabs() {
      this.reset()
    },
    calculateTimes() {
      this.workout.rounds = 1
      if (this.xmode == 'stopwatch') {
        this.workout.active.minutes = Number.parseInt(120)
      }
      this.workout.active.total = Number.parseInt(
        this.workout.active.minutes * 60
      )
      this.workout.active_time = this.workout.active.total
      this.workout.total_time = this.workout.active.minutes
    },
    reset() {
      clearInterval(this.interval)
      this.completed = false
      this.value = 0
      this.valueMinutes = '00'
      this.valueSeconds = '00'
      this.elapsedMinutes = 0
      this.elapsedSeconds = 0
      this.elapsedMinutesStr = '00'
      this.elapsedSecondsStr = '00'
      this.xmode = 'time'
      this.workout = { ...this.initial }
      this.workout.active.minutes = 15
      this.workout.active.seconds = 0
      this.workout.active.total = 0
      this.rounds = 0
      this.$v.$reset()
      this.switchNum++
      this.screenOff()
    },
    playRestEnd() {
      this.audio.play()
    },
    playBeep() {
      this.beep.play()
    },
    playSilence() {
      this.silence.play()
    },
    elapsed() {
      let startMoment = this.startTime
      this.elapsedSeconds = moment().diff(startMoment, 'seconds')
      this.elapsedMinutes = moment().diff(startMoment, 'minutes')

      let justSeconds = this.elapsedSeconds % 60

      this.elapsedSecondsStr = Number.parseInt(Math.floor(justSeconds))
        .toString()
        .padStart(2, '0')

      this.elapsedMinutesStr = Number.parseInt(Math.floor(this.elapsedMinutes))
        .toString()
        .padStart(2, '0')
    },
    startActivity() {
      this.dialog = true

      this.startActive()
    },
    startActive() {
      this.screenOn()
      this.startTime = moment()
      let remaining = Math.ceil(this.workout.active_time)

      let startMoment = this.startTime
      let endMoment = moment(this.startTime).add(
        this.workout.active_time,
        'seconds'
      )
      this.endTime = endMoment.format(' h:mm:ss A')
      this.value = remaining
      this.interval = setInterval(() => {
        if (
          remaining % 30 == 1 &&
          remaining != this.workout.active_time &&
          remaining > this.soundCutoff + 1
        ) {
          this.playBeep()
        } else if (remaining == this.soundCutoff + 1) {
          this.playRestEnd()
        } else if (remaining <= 0) {
          this.screenOff()
          clearInterval(this.interval)
          this.saveActivity()
          return true
        }
        remaining =
          parseInt(this.workout.active_time) -
          moment().diff(startMoment, 'seconds')

        this.elapsed()

        this.value = remaining

        this.valueMinutes = Number.parseInt(Math.floor(this.value / 60))
          .toString()
          .padStart(2, '0')
        this.valueSeconds = Number.parseInt(Math.ceil(this.value % 60))
          .toString()
          .padStart(2, '0')
      }, this.tic)
    },
    setDropDowns() {
      this.sauna = this.wellness.filter(function(item) {
        return item.category == 'Sauna'
      })
      this.cold = this.wellness.filter(function(item) {
        return item.category == 'Cold Therapy'
      })
      this.meditation = this.wellness.filter(function(item) {
        return item.category == 'Meditation'
      })
      this.selectedType = this.sauna[0]
      this.minutesChanged()
    },
    loadWellness() {
      {
        return axios
          .get(this.baseURL + '/activities/wellness', {})
          .then(response => {
            if (response.status == 200) {
              this.wellness = response.data.data
              this.setDropDowns()
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    saveActivity() {
      let now = moment(this.startTime).format('YYYY-MM-DD  HH:mm:ss')
      return axios
        .post(this.baseURL + '/activities', {
          activityid: this.selectedType.id,
          calories: 0,
          totalCalories: 0,
          duration: this.duration,
          distance_imperial: 0,
          distance_metric: 0,
          start_time: now
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Activity was succesfully saved..')
            this.completed = false
            this.$router.push('/activities')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
