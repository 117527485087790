<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto mt-5 mb-10 paper" elevation="1">
          <v-card-title class="pb-5">
            <BaseScreenHeader
              title="Add Rep Scheme"
              width="90%"
              screenInfo="RSC-001"
              :showBack="true"
            />
          </v-card-title>
          <AddRepScheme />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const AddRepScheme = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/AddRepScheme.vue')

export default {
  components: { AddRepScheme },
  name: 'AddRepSchemeView',
  data: () => ({})
}
</script>
