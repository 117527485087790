<template>
  <v-container>
    <BaseScreenHeader title="User List" screenInfo="ADM-001" :showBack="true" />

    <v-card v-if="xvy" class="transparent pa-0">
      <v-card-text class="text-center text-uppercase pa-0 silver--text">
        <span class="pa-1" v-for="letter in alphabet" :key="letter">
          <v-btn
            :small="!isPhone"
            :x-small="isPhone"
            class="mt-2 "
            @click="reload(letter)"
            :color="color(letter)"
            :title="'Users Starting With: ' + letter"
          >
            {{ letter }}
          </v-btn>
        </span>
      </v-card-text>

      <v-card-text class="px-0 silver--text">
        <v-row dense>
          <v-spacer />
          <v-col cols="12">
            <v-text-field
              dark
              clearable
              filled
              color="success"
              class="enlarged-input pb-4 px-3"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              :label="'Search ' + usersList.length + ' users'"
              single-line
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="item in usersList" :key="item.id" cols="12" md="6">
            <v-card
              rounded
              :elevation="0"
              class="pa-2 mx-2 charcoal charcoalTile silver--text mb-2"
            >
              <v-card-text
                :class="textSizeSmall + ' paper--text px-0 pt-1 pb-0'"
              >
                {{ item.fname }} {{ item.lname }}
                <v-spacer />
                <span class="caption progressActive--text">
                  Registered: {{ prettyDateTime(item.created) }}
                </span>
              </v-card-text>
              <v-card-actions :class="textSizeXSmall + ' pa-0'">
                {{ item.username }}
                <v-spacer />
              </v-card-actions>
              <template v-if="item.name != null">
                <v-card-text :class="textSizeSmall + ' silver--text px-0 pb-0'">
                  {{ item.name }}
                </v-card-text>
                <v-card-text
                  class="purpleAccent--text pt-0 px-0"
                  v-if="item.name != null"
                >
                  {{ prettyDate(item.startdate) }} to
                  {{ prettyDate(item.enddate) }}
                </v-card-text>
              </template>
              <v-card-actions v-else class="px-0 ml-n2">
                <v-btn color="silver" plain @click="givePro(item.id)">
                  <v-icon color="silver">
                    mdi-arrow-up-box
                  </v-icon>
                  Give PRO
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
export default {
  name: 'AdminUsers',
  components: {},
  mixins: [util],
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      showEdit: false,
      loaded: false,
      selectedForEdit: {},
      showDetailsDialog: false,
      showAddDialog: false,
      showTagDialog: false,
      showRowInfo: false,
      activity: '',
      added: '',
      search: '',
      letter: 'all',
      usersList: [],
      allTags: [],
      glossaryTags: [],
      alphabet: []
    }
  },
  beforeMount() {
    this.loadUsers()
    this.alphabet = this.getAlphabet
  },
  computed: {
    ...appConfig
  },
  methods: {
    color(letter) {
      if (this.letter == letter) return 'accent'
    },
    reload(letter) {
      this.letter = letter
      this.loadUsers()
    },
    saved() {
      this.loadUsers()
      this.showDetailsDialog = false
    },
    selectItem(value) {
      this.showEdit = true
      this.selectedForEdit = value
    },
    tagItem(value) {
      this.showTagDialog = true
      this.selectedForEdit = value
    },
    givePro(userid) {
      return axios
        .put(this.baseURL + '/admin/givepro', {
          userid: userid
        })
        .then(response => {
          if (response.status == 200) {
            this.loadUsers()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    loadUsers() {
      return axios
        .get(this.baseURL + '/admin/users/' + this.letter, {})
        .then(response => {
          if (response.status == 200) {
            this.usersList = response.data.data
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
